import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`A price oracle is any tool used to view price information about a given asset. When you look at stock prices on your phone, you are using your phone as a price oracle. Similarly, the app on your phone relies on devices to retrieve price information - likely several, which are aggregated and then displayed to you, the end-user. These are price oracles as well.`}</p>
    <p>{`When building smart contracts that integrate with DeFi protocols, developers will inevitably run into the price oracle problem. What is the best way to retrieve the price of a given asset on-chain?`}</p>
    <p>{`Many oracle designs on VeChain have been implemented on an ad-hoc basis, with varying degrees of decentralization and security. Because of this, the ecosystem has witnessed numerous high-profile hacks where the oracle implementation is the primary attack vector.
Some of these vulnerabilities are discussed `}<a parentName="p" {...{
        "href": "https://samczsun.com/taking-undercollateralized-loans-for-fun-and-for-profit/"
      }}>{`here`}</a>{`.`}</p>
    <p>{`While there is no one size fits all solution, Vexchange V2 enables developers to build highly decentralized and manipulation-resistant on-chain price oracles, which may solve many of the demands necessary for building robust protocols.`}</p>
    <h1 {...{
      "id": "vexchange-v2-solution",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#vexchange-v2-solution",
        "aria-label": "vexchange v2 solution permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Vexchange V2 solution`}</h1>
    <p>{`Vexchange V2 includes several improvements for supporting manipulation-resistant public price feeds. First, every pair measures (but does not store) the market price at the beginning of each block, before any trades take place. This price is expensive to manipulate because it is set by the last transaction, whether it is a mint, swap, or burn, in a previous block.`}</p>
    <p><strong parentName="p">{`To set the measured price to one that is out of sync with the global market price, an attacker has to make a bad trade at the end of a previous block`}</strong>{` , typically with no guarantee that they will arbitrage it back in the next block. Attackers will lose money to arbitrageurs unless they can “selfishly” mine two blocks in a row. This type of attack presents several challenges and `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/1912.01798"
      }}>{`has not been observed to date`}</a>{`.`}</p>
    <p>{`Unfortunately, this alone is not enough. If significant value settles based on the price resulting from this mechanism, an attack’s profit will likely outweigh the loss.`}</p>
    <p>{`Instead, Vexchange V2 adds this end-of-block price to a single cumulative-price variable in the core contract weighted by the amount of time this price existed. `}<strong parentName="p">{`This variable represents a sum of the Vexchange price for every second in the entire history of the contract.`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/305e79bfff0602175a5d2948fc4a820d/35c87/v2_onchain_price_data.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAACE4AAAhOAFFljFgAAACDUlEQVQ4y41Ui3KjMAzk/z/v2mRu2iOkTY9nAPMwxmB7TzIJk6T0Ws9oPH5otStZDgBYOMDRwA/H/64G1lh7CA8Iw9BVVYWyLJFlGc7n82pFUUAI4ffbtl1Bt4ADSyPPc6Rp6tghSRJ8nE4ehI32yRIfKI5jcNBHwFvgwEu+KLmXdT//VP4F0EdzRBZq1CRLoiHrOgk9TZjmGXwmRONTwLJ5vclwwbF8wdfFkA2pgHzNMcRioWgWZ6VGDMOAkYJqrbcB6ZJ9et7j19PORcd3r9v0I6a0xdwouJOAJXOT+VLyrXmGxhjMxriZpI0kUaUNBmaYEBCxx+wVoKkEyo8Uqpce7Cr7tkABgVnOTS2E49nMBmlf40XEfuaL1vFTdZB1B5lUUI3ENM2bbAPKhX15/YPo+Oby4kzpWtgwU2KNWJbetLkHKOhuGEZgn3NZoarFAvj4bKg2KHqBiBgWxND6QlkfqCWnJE/Ryt5X3xdIc4HoJdB6zSEzMpxDYsSHuZCI8hZZLS+SKZZdJCeCOqer0athu/XGcbS7/W887/bu+HbyAGWjcUgGFEIjrmf8rWbo2T0m7FOFV4b+8U6T40RzpfNaIcoUskqRVEe5ZLO0LnA4ZcirnnJ8Bfmm9Ri07wd0ZFKqtffYqVcSoqMzqdEP+utOuWyuPxi9JGJq/Pzdd/VFL1/PPn9Jtyy27BH0H7upMQHj8oo1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "v2 onchain price data",
            "title": "v2 onchain price data",
            "src": "/static/305e79bfff0602175a5d2948fc4a820d/c1b63/v2_onchain_price_data.png",
            "srcSet": ["/static/305e79bfff0602175a5d2948fc4a820d/5a46d/v2_onchain_price_data.png 300w", "/static/305e79bfff0602175a5d2948fc4a820d/0a47e/v2_onchain_price_data.png 600w", "/static/305e79bfff0602175a5d2948fc4a820d/c1b63/v2_onchain_price_data.png 1200w", "/static/305e79bfff0602175a5d2948fc4a820d/d61c2/v2_onchain_price_data.png 1800w", "/static/305e79bfff0602175a5d2948fc4a820d/97a96/v2_onchain_price_data.png 2400w", "/static/305e79bfff0602175a5d2948fc4a820d/35c87/v2_onchain_price_data.png 2569w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This variable can be used by external contracts to track accurate time-weighted average prices (TWAPs) across any time interval.`}</p>
    <p>{`The TWAP is constructed by reading the cumulative price from an VIP180 token pair at the beginning and at the end of the desired interval. The difference in this cumulative price can then be divided by the length of the interval to create a TWAP for that period.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f134d0d4763a11354a400c3c944aecad/c6f3b/v2_twap.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAACE4AAAhOAFFljFgAAABnElEQVQoz41Ti46bMBDM//9gA+FyFwgQHsfD+AXGnq43VZpUF6mWxjaL2Z0ZLwcExHGf/3OE8P74ITkmSNM0nM9nXC4XlGWJoigYdV3zGmN5nuN6vWIYhkfSn3AwxkBrHcZxhBACSilM0wQpJe9jLGJZFl6ttS9Mn8EM46SUDt6/yojP27a9xJxzWNf1rWxmOI4C0yzCIjW0stjoI6k0xmmheGQlOdE4CcYiFaReqZhjJV3XoW1bVsMMi6JCmn2E8bOB+ehJ0sqJun5E/z3he5iJ1YaqapGeMnyVPbLacNKcPE+SBFmWsb/eexxidaoW9m2Hd/5Bfd89H/B/pDm3M6sY32i//yP5cSnxpXM+WGIxC5K4KJaltOE1spVkx/0c2WEcjN6wSgtLMbtFXwlEiBOWVUNSzuxhXpQ4Jiecsk985RV+HVPeDyRdUIJm0EgrhVsxwSQ3yGaGXgyKsiYv7+10UNpSSyhmGKvcbi2atmfvyqrGIlRUgDkmHC26eYWYNGy3wNQzjIh+UusZe2f414Lw9g/wP7XIE559/A2y/1QNA7UCEgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "v2 twap",
            "title": "v2 twap",
            "src": "/static/f134d0d4763a11354a400c3c944aecad/c1b63/v2_twap.png",
            "srcSet": ["/static/f134d0d4763a11354a400c3c944aecad/5a46d/v2_twap.png 300w", "/static/f134d0d4763a11354a400c3c944aecad/0a47e/v2_twap.png 600w", "/static/f134d0d4763a11354a400c3c944aecad/c1b63/v2_twap.png 1200w", "/static/f134d0d4763a11354a400c3c944aecad/d61c2/v2_twap.png 1800w", "/static/f134d0d4763a11354a400c3c944aecad/97a96/v2_twap.png 2400w", "/static/f134d0d4763a11354a400c3c944aecad/c6f3b/v2_twap.png 2950w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`TWAPs can be used directly or as the basis for moving averages (EMAs and SMAs) as needed.`}</p>
    <p>{`A few notes:`}</p>
    <ul>
      <li parentName="ul">{`For a 10-minute TWAP, sample once every 10 minutes. For a 1-week TWAP, sample once every week.`}</li>
      <li parentName="ul">{`For a simple TWAP, the cost of manipulation increases (approx. linear) with liquidity on Vexchange, as well as (approx. linear) with the length of time over which you average.`}</li>
      <li parentName="ul">{`The Cost of an attack is relatively simple to estimate. Moving the price 5% on a 1-hour TWAP is approximately equal to the amount lost to arbitrage and fees for moving the price 5% every block for 1 hour.`}</li>
    </ul>
    <p>{`There are some nuances that are good to be aware of when using Vexchange V2 as an oracle, especially where manipulation resistance is concerned. The `}<a href='/whitepaper.pdf' target='_blank' rel='noopener noreferrer'>{`whitepaper`}</a>{` elaborates on some of them. Additional oracle-focused developer guides and documentation will be released soon.`}</p>
    <h2 {...{
      "id": "manipulation-resistance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#manipulation-resistance",
        "aria-label": "manipulation resistance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manipulation resistance`}</h2>
    <p>{`The cost of manipulating the price for a specific time period can be roughly estimated as the amount lost to arbitrage and fees every block for the entire period. For larger liquidity pools and over longer time periods, this attack is impractical, as the cost of manipulation typically exceeds the value at stake.`}</p>
    <p>{`Other factors, such as network congestion, can reduce the cost of attack. For a more in-depth review of the security of Vexchange V2 price oracles, read the `}<a parentName="p" {...{
        "href": "https://Vexchange.org/audit.html#org87c8b91"
      }}>{`security audit section on Oracle Integrity`}</a>{`.`}</p>
    <h1 {...{
      "id": "building-an-oracle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#building-an-oracle",
        "aria-label": "building an oracle permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Building an oracle`}</h1>
    <p>{`To learn more about building oracles check out `}<Link to="/docs/v2/smart-contract-integration/building-an-oracle/" mdxType="Link">{`building an oracle`}</Link>{` in the developer guides.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      